<template>
  <div class="page-not-found">
    <h1 class="qj-h2 mb-1">Página não encontrada</h1>

    <div class="retry-button">
      <v-btn color="qj-bg-primary" v-bind:href="url">
        Voltar para o início
      </v-btn>
    </div>

    <div class="image">
      <img src="@/assets/img/errors/error_404.png"
        alt="Página não encontrada." />
    </div>

    <div class="version">
      {{ version }}
    </div>
  </div>
</template>

<script>
import { version } from "@/../package.json";

export default {
  data: () => ({
    version: version,
  }),
  computed: {
    url() {
      // Passa o parâmetro "t" para evitar o cache do index.html
      return "/?t=" + new Date().getTime();
    },
  },
};
</script>

<style scoped lang="scss">
.page-not-found {
  padding: 32px 8px;
  text-align: center;

  h1 {
    margin-bottom: 16px;
  }

  .retry-button {
    margin: 32px 0;
  }

  .version {
    color: #aaaaaa;
    font-size: 14px;
    word-break: break-all;
  }

  .image {
    margin: 64px 0 0;
    text-align: center;

    img {
      max-width: 100%;
      width: 400px;
    }
  }
}
</style>
