import router from "@/router";

/**
 * Verifica qual o próximo challenge.
 */
function resolveChallenge(type, params = {}, continueTo) {
  console.info("Login challenge:", type);

  let query = { continue: continueTo };

  const authTypeRouteNames = {
    "BIRTHDAY": 'AuthBirthday',
    "CONTACT_PASSCODE" : "AuthPasscode",
    "DOCUMENT" : "Authentication",
    "MOTHER_NAME" : "AuthMotherName",
    "RELIABLE_CONTACT" : "AuthLanding",
  }
  try {
    router.push({ name: authTypeRouteNames[type], params: params, query }).catch(() => {});
  } catch (error) {
    console.error("Challenge não previsto:", type, error);
  }
}

export default {
  resolveChallenge,
}
