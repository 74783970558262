<template>
  <div>
    <v-row class="qj-row-main content-container">
      <v-col cols="12" class="pt-1">
        <h1 class="qj-h1 mb-2" v-if="userInfo.customer_type == 'PERSONAL'">
          Olá {{ userInfo.name }},
        </h1>
        <h1 class="qj-h1 mb-2" v-else>Olá,</h1>
        <p class="mb-0 qj-p">
          Nos informe o seu
          <b>número de celular com DDD:</b>
        </p>
      </v-col>
      <v-col cols="12" class="pt-2 flex-content phone-container">
        <v-text-field
          type="tel"
          autocomplete="tel"
          dense
          autofocus
          outlined
          required
          single-line
          hide-details
          class="qj-input mr-2"
          prepend-inner-icon="fas fa-mobile-alt"
          v-mask="'(##) #####-####'"
          v-model="phone"
          @keyup="send"
          data-hj-allow
          id="input__phone__to__sms"
        ></v-text-field>
        <span class="phone-sample">Ex: (41) 99999-9999</span>
      </v-col>
      <v-col cols="4" class="mx-auto pt-2 justify-center d-flex">
        <v-btn
          class="rounded-lg qj-btn btn-cpf qj-bg-primary"
          :loading="loading"
          :disabled="loading || !phoneValidation"
          @click="send"
          @keydown.enter="send"
          id="btn__send__phone__number"
        >
          Prosseguir
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="!error">
        <p class="qj-p mb-0">
          Para sua segurança, confirmaremos o seu número com o
          <b>código de verificação</b> enviado <b>via SMS.</b>
        </p>
      </v-col>
      <v-col cols="12" v-else>
        <v-alert
        :value="error"
        color="red"
        text
        transition="scale-transition"
        class="qj-alert-error text-center"
      >
        Telefone inválido.
        <br />Verifique e tente novamente.
      </v-alert>
      </v-col>
    </v-row>
    <qj-footer />
    <DialogLoginError :dialogLoginError="dialogLoginError"/>
  </div>
</template>

<script>
import Authentication from "./authentication";
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import { createTagManagerEvent } from "@/utils";
import DialogLoginError from "../components/modals/DialogLoginError";

export default {
  components: {
    DialogLoginError,
  },
  data: () => ({
    userInfo: store.getters["authentication/getUserInfo"],
    error: false,
    dialogLoginError: false,
    loading: false,
    disabled: false,
    phone: "",
    errorCount: 0,
  }),
  beforeRouteEnter(to, from, next) {
    if (store.getters["authentication/getToken"] && !from.meta.requiresAuth) {
      next();
    } else {
      next({ name: "Authentication" });
    }
  },
  methods: {
    ...mapActions("authentication", [
      "validateChallenge",
    ]),
    ...mapMutations('authentication', {
      setChallengeResultObject: "setChallengeResultObject",
    }),
    send() {
      if (!this.phoneValidation) return;

      if (this.loading) {
        return;
      }

      createTagManagerEvent({
        event: "loginSubmittedPhone",
      });

      this.loading = true;
      this.error = false;

      let payload = {
        answer: this.getPhoneString,
        type: "RELIABLE_CONTACT",
        token: store.getters["authentication/getToken"],
      };

      if (typeof this.$route.params.oldParams !== "undefined") {
        payload = this.$route.params.oldParams;
        payload.answer = this.getPhoneString;
      }

      this.validateChallenge(payload)
        .then(() => {
          this.challengeSuccess(payload);

        })
        .catch((error) => {
          // Erro 401: challenge inválido
          if (error.response && error.response.status == 401) {
            this.error = true;
            this.$vuetify.goTo(100);
            return;
          }

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => (this.loading = false));
    },
    showLoginErrorModal(){
      this.dialogLoginError = true
    },
    challengeSuccess(payload) {
      let resultObject = this.$jwt.decode(store.getters["authentication/getChallengeResultToken"])
      if (resultObject.attempts >= 3){
        this.showLoginErrorModal()
      } else if (resultObject.attempts >= 0){
        this.error = true
      }
      this.setChallengeResultObject(
        resultObject
      );
      Authentication.resolveChallenge(
        store.getters["authentication/getChallengeResultObject"].challenge,
        payload,
        this.$route.query.continue
      );
    },
  },
  computed: {
    phoneValidation() {
      return this.getPhoneString.length < 11 ? false : true;
    },
    getPhoneString() {
      return this.phone
        .replace(/[()]/g, "")
        .replace(/-/g, "")
        .replace(/ /g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-row-main {
  margin-bottom: 0;
  min-height: auto;
}

.qj-btn.qj-bg-secondary-light {
  border-radius: 0.5em;
  width: 13.5em;
}
.flex-content {
  display: inline-flex;
  justify-content: space-around;
}

.use-code-text {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: 1.5em 1em 0;
}

.phone-container {
  flex-direction: column;
  .phone-sample {
    margin-top: 3px;
    display: block;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #b4b4b4;
  }
}

.action-buttons-container {
  justify-content: center;
  display: flex;
  .proceed-button {
    width: 228px;
    height: 36px;
  }
  .help-button {
    width: 36px !important;
    height: 36px !important;
    min-width: unset !important;

    color: #00e58d;
    background-color: #cafce9 !important;
    border-radius: 10px;
    margin-left: 20px;
  }
  .margin {
    margin-top: 56px;
  }
}
</style>
