<template>
    <v-dialog v-model="dialogLoginError" persistent max-width="372">
      <v-card class="text-center">
        <v-card-title class="justify-center d-flex flex-column">
          <h3 class="qj-h3 mb-2">Desculpe...</h3>
          <img src="@/assets/img/svg/proposalError.svg" />
        </v-card-title>
        <v-card-text>
          <p class="qj-card-text">
            Não foi possivel validar o seu acesso!
          </p>
          <v-btn
            color="#00e58d"
            class="rounded-lg qj-btn v-btn mx-auto px-10"
            @click="closeDialog()"
            >Entendi</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    dialogLoginError: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: String,
      default: null,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      window.location = 'https://quiteja.com.br'
    }
  },
};
</script>

<style lang="scss" scoped>
.qj-h3 {
  word-break: break-word;
}
</style>
