<template>
  <div class="page-error">
    <h2 class="qj-h2 mb-1">Ooops. Houve um problema.</h2>

    <div class="retry-button">
      <v-btn color="qj-bg-primary" v-bind:href="url">
        Tentar novamente
      </v-btn>
    </div>

    <div class="image">
      <img src="@/assets/img/errors/error_500.png" alt="Erro" />
    </div>

    <div v-if="errorId" class="error-id">
      <div>{{ errorId }}</div>
    </div>

    <div class="version">
      {{ version }}
    </div>
  </div>
</template>

<script>
import { version } from "@/../package.json";

export default {
  data: () => ({
    errorId: null,
    version: version,
  }),
  created() {
    this.errorId = this.$route.query.id;
    setTimeout(() => this.$emit("loading", false), 300);
  },
  computed: {
    url() {
      // Passa o parâmetro "t" para evitar o cache do index.html
      return "/?t=" + new Date().getTime();
    },
  },
};
</script>

<style scoped lang="scss">
.page-error {
  padding: 32px 8px 0 8px;
  text-align: center;

  h1 {
    margin-bottom: 16px;
  }

  .retry-button {
    margin: 32px 0;
  }

  .error-id,
  .version {
    color: #aaaaaa;
    font-size: 14px;
    word-break: break-all;
  }

  .image {
    margin: 64px 0 0;
    text-align: center;

    img {
      max-width: 100%;
      width: 400px;
    }
  }
}
</style>
