<template>
  <div class="page-error d-flex flex-column">
    <div class="image">
      <span class="erro-status">503</span>
      <img src="@/assets/img/errors/background.png" alt="Erro" />
    </div>

    <div v-if="errorId" class="error-id">
      <div></div>
    </div>

    <div class="mt-12 text-left">
      <h1 class="text-center">Ops!</h1>
      <span>
        No momento, suas ofertas d{{ getPrefix }}
        <span class="font-weight-bold">{{ selectedDeal.creditor.name }}</span>
        não estão disponíveis.
      </span>
      <p class="mt-4">
        Acesse nosso site entre
        <span class="font-weight-bold">07h e 23h</span> para conferir as
        propostas.
      </p>
    </div>
    <div class="reminder text-left" v-if="hasActiveDeals">
      Vale lembrar que suas ofertas de outras pendências podem ser acessadas
      normalmente.
    </div>
    <div class="redirect mx-auto" v-if="hasActiveDeals || hasAgreedDeals">
      <p class="my-0">
        <span
          >Em <b>{{ seconds }} segundos</b> você será</span
        >
      </p>
      <p>redirecionado para a página inicial</p>
    </div>
  </div>
</template>

<script>
import { version } from "@/../package.json";
import { mapGetters } from "vuex";
// import moment from "moment";

export default {
  data: () => ({
    errorId: null,
    version: version,
    error: null,
    retryAfter: null,
    seconds: 15,
    interval: null,
  }),
  created() {
    if (!this.selectedDeal) {
      this.$router.push({
        name: "Deals",
      });
    }
    this.$emit("loading", false);
    if (!this.errorResponse) {
      this.$router.push({ name: "Deals" });
      return;
    }
    this.errorId = this.$route.query.id;
    this.redirectToHome();
  },
  methods: {
    redirectToHome() {
      if (this.hasActiveDeals || this.hasAgreedDeals) {
        this.interval = setInterval(() => {
          this.seconds -= 1;
          if (this.seconds === 0) {
            this.$router.push({
              name: "Deals",
            });
            clearInterval(this.interval);
          }
        }, 1000);
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'NegotiationDeal' && to.params.stay){
      next()
      return
    }
    next({ name: 'Home'})
   },
  computed: {
    ...mapGetters("error", {
      errorResponse: "getErrorResponse",
      errorRequest: "getErrorRequest",
    }),
    ...mapGetters("negotiation", {
      selectedDeal: "getSelectedDeal",
      deals: "getDeals",
    }),
    url() {
      // Passa o parâmetro "t" para evitar o cache do index.html
      return "/?t=" + new Date().getTime();
    },
    getPrefix() {
      return this.selectedDeal?.creditor?.profile?.gender == "MALE" ? "o" : "a";
    },
    hasActiveDeals() {
      return this.deals?.some((deal) => {
        return deal.status == "STARTED" && deal.id !== this.selectedDeal.id;
      });
    },
    hasAgreedDeals() {
      return this.deals?.some((deal) => {
        return deal.status == "AGREED" && deal.id !== this.selectedDeal.id;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
.erro-status {
  font-style: normal;
  font-weight: 800;
  font-size: 128px;
  line-height: 156px;
  position: absolute;
  padding-top: 50px;
  z-index: 1;
  color: var(--primary);
}
.image {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: relative;
    z-index: 2;
  }
}
.page-error {
  padding: 32px 8px 0 8px;
  text-align: center;
  height: 100%;

  h1 {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 36px;
    line-height: 18px;
  }

  .retry-button {
    margin: 32px 0;
  }

  .error-id,
  .version {
    color: #aaaaaa;
    font-size: 14px;
    word-break: break-all;
  }

  .image {
    text-align: center;

    img {
      max-width: 100%;
      width: 400px;
    }
  }
}
.redirect {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
