<template>
  <div>
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" class="pt-6">
          <h1 class="qj-h1 mb-2" v-if="userInfo.customer_type == 'PERSONAL'">
            {{ userInfo.name }},
          </h1>
          <h1 class="qj-h1 mb-2" v-else>Olá,</h1>
          <p class="mb-0 qj-p">
            Precisamos que você informe o <b> nome completo da sua mãe</b> para
            que possamos fazer uma validação final dos seus dados
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="send">
            <v-text-field
              dense
              outlined
              required
              autofocus
              hide-details
              class="qj-input mr-2"
              v-model="motherName"
              data-hj-allow
            >
            </v-text-field>
            <span class="phone-sample">Ex: Fulana Da Silva Santos</span>

            <div class="mt-4 text-center">
              <v-btn
                class="mx-auto rounded-lg qj-btn qj-bg-primary"
                :loading="loading"
                :disabled="loading"
                type="submit"
              >
                Prosseguir
              </v-btn>
            </div>
            <v-alert
              text
              color="red"
              transition="scale-transition"
              class="mt-4 qj-alert-error text-center"
              :value="error"
            >
              O nome não confere.
              <br />Verifique e tente novamente.
            </v-alert>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
    <qj-footer view="mother-name" />

    <DialogLoadingDebts
      :dialogLoadingDebts="dialogLoadingDebts"
      @policy="$emit('policy', $event)"
      @debitsLoaded="$emit('debitsLoaded', $event)"
    />
    <DialogLoginError :dialogLoginError="dialogLoginError" />
  </div>
</template>

<script>
import Authentication from "./authentication";
import store from "@/store";
import { mapActions, mapGetters, mapMutations } from "vuex";
import environment from "@/assets/js/environment";
import DialogLoadingDebts from "../components/modals/DialogLoadingDebts";
import DialogLoginError from "../components/modals/DialogLoginError";

export default {
  components: {
    DialogLoadingDebts,
    DialogLoginError,
  },

  data: () => ({
    userInfo: store.getters["authentication/getUserInfo"],
    motherName: null,
    dialogLoginError: false,
    modal: false,
    error: false,
    dialogLoadingDebts: false,
    loading: false,
  }),

  beforeRouteEnter(to, from, next) {
    if (!store.getters["authentication/getToken"]) {
      next({ name: "Authentication" });
    } else {
      next();
    }
  },

  methods: {
    ...mapActions("authentication", [
      "validateChallenge",
    ]),
    ...mapMutations("authentication", {
      setCookie: 'setAuthTokenToCookie',
      setChallengResultObject: "setChallengResultObject",
      setUserInfo: 'setUserInfo',
    }),
    ...mapActions("authentication", {
      setCookie: 'setAuthTokenToCookie'
    }),
    ...mapGetters("authentication", {
      challengeResultObject: "getChallengeResultObject",
      token: "getToken",
    }),
    showLoginErrorModal() {
      this.dialogLoginError = true;
    },
    send() {
      if (!this.motherName) {
        return;
      }

      console.debug("Enviando nome da mãe:", this.motherName);

      this.error = false;
      this.loading = true;

      let payload = {
        answer: this.motherName,
        type: "MOTHER_NAME",
        token: this.token(),
      };

      this.validateChallenge(payload)
        .then(() => {
          console.debug("Nome da mãe validado com sucesso.");
          this.challengeSuccess(payload);
        })
        .catch((error) => {
          console.error("Não foi possível fazer o login.", error);

          if (error.response && error.response.status == 401) {
            this.showLoginErrorModal();
            return;
          }

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    challengeSuccess(payload) {
      let authResponse = this.$jwt.decode(this.token());
      if (authResponse.attempts == 3) {
        this.showLoginErrorModal();
      } else if (authResponse.attempts > 0) {
        this.error = true;
      }

      if (authResponse.token_type == "AUTH") {
        this.setUserInfo(authResponse.customer);

        this.setCookie({payload: null, vm: this})

        this.createTagManagerEvent({ event: "Login" });

        this.dialogLoadingDebts = true;
      } else if (authResponse.token_type == "PRE_AUTH") {
        this.ActionsSetChallengeResultObject(authResponse);

        Authentication.resolveChallenge(
          authResponse.challenge,
          payload,
          this.$route.query.continue
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.phone-sample {
  margin-top: 5px;
  display: block;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b4b4b4;
}
</style>
