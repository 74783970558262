var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0 pt-1"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(_vm.userInfo.customer_type == 'PERSONAL')?_c('h1',{staticClass:"qj-h1 mb-3"},[_vm._v(" Olá "+_vm._s(_vm.userInfo.name)+", ")]):_c('div',[_c('h1',{staticClass:"qj-h1 mb-3"},[_vm._v("Olá, tudo bem?")]),_c('p',{staticClass:"greetings-company"},[_vm._v("Confira as propostas para a empresa")]),_c('p',{staticClass:"company-name"},[_vm._v(" "+_vm._s(_vm.capitalizeName(_vm.userInfo.name))+" ")])]),_c('p',{staticClass:"mb-0 qj-p"},[_vm._v(" Para aproveitar as ofertas disponíveis para você, precisamos confirmar seu "),_c('b',[_vm._v(_vm._s(_vm.userInfo.customer_type == "PERSONAL" ? "CPF" : "CNPJ"))]),_vm._v(". Complete os números para prosseguir. ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-2 flex-content",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center",class:_vm.userInfo.customer_type == 'PERSONAL' ? 'cpf-container' : 'cnpj-container'},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.setInputMask()),expression:"setInputMask()"}],ref:"inputCpf",staticClass:"qj-input qj-bold qj-input-center mr-2",attrs:{"dense":"","outlined":"","required":"","single-line":"","type":"text","autocomplete":"off","inputmode":"numeric","prepend-inner-icon":"far fa-user","placeholder":_vm.userInfo.customer_type == 'PERSONAL'
              ? '___.___-__'
              : '___.___/____-__',"prefix":_vm.userInfo.customer_type == 'PERSONAL'
              ? `${_vm.userInfo.document}.`
              : _vm.userInfo.document?.[0] +
                _vm.userInfo.document[1] +
                '.' +
                (_vm.userInfo.document[2] ?? ''),"hide-details":"","data-hj-allow":""},on:{"keyup":_vm.send},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}}),_c('button',{on:{"click":function($event){_vm.dialogWhyConfirm = true}}},[_c('v-icon',[_vm._v("far fa-question-circle")])],1)],1)])],1),_c('v-row',[(_vm.error)?_c('v-col',{staticClass:"pa-0 flex-content centered",attrs:{"cols":"12"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"invalid-CPF-message"},[_vm._v(" "+_vm._s(_vm.userInfo.customer_type == "PERSONAL" ? "CPF" : "CNPJ")+" é inválido. "),_c('br'),_vm._v("Verifique e tente novamente. ")])]):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto pt-2 justify-center",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"rounded-lg qj-btn btn-cpf qj-bg-primary",attrs:{"loading":_vm.loading,"disabled":_vm.loading || !_vm.isValidDocument},on:{"click":_vm.send}},[_vm._v(" Prosseguir ")])],1)],1),_c('v-row',{staticStyle:{"margin-bottom":"80px !important"}},[_c('v-col',{staticClass:"pt-6 text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 qj-p px-3"},[_vm._v(" Com a "),_c('b',[_vm._v("QuiteJá")]),_vm._v(" você pode reestruturar sua vida financeira! Negociamos dívidas de forma "),_c('b',[_vm._v("rápida, segura e descomplicada.")])])])],1),_c('DialogWhyConfirm',{attrs:{"dialogWhyConfirm":_vm.dialogWhyConfirm},on:{"close":function($event){_vm.dialogWhyConfirm = false}}}),_c('v-row',{staticClass:"qj-footer-abs"},[_c('v-col',{staticClass:"align-self-end"},[_c('qj-footer',{attrs:{"type":"notMyCPF"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }