<template>
  <v-form ref="form" lazy-validation>
    <!-- Body -->
    <v-row class="qj-row-main">
      <!-- Title -->
      <v-col cols="12" class="pt-4 pb-0">
        <h1 class="qj-h2 pb-2">Boas-vindas a QuiteJá!</h1>
        <p class="qj-p">
          Nos conte um pouco sobre você, assim poderemos te ajudar a encontrar
          uma solução para o seu problema financeiro.
        </p>
      </v-col>
      <!-- Input name -->
      <v-col cols="12" class="pb-0">
        <v-text-field
          dense
          outlined
          required
          single-line
          label="Nome"
          class="qj-input"
          prepend-inner-icon="far fa-user"
          v-model="form.name"
          :rules="rules.name"
          @keyup.enter="submit()"
          autocomplete="name"
        ></v-text-field>
      </v-col>
      <!-- Input email -->
      <v-col cols="12" class="pb-0 pt-0">
        <v-text-field
          type="email"
          dense
          outlined
          required
          single-line
          persistent-hint
          label="E-mail"
          class="qj-input"
          prepend-inner-icon="far fa-envelope"
          hint="Informe um e-mail que você costuma ter acesso."
          v-model="form.email"
          :rules="rules.email"
          @keyup.enter="submit()"
          autocomplete="email"
        ></v-text-field>
      </v-col>
      <!-- Input number -->
      <v-col cols="12" class="pb-0 pt-0">
        <v-text-field
          type="tel"
          dense
          outlined
          required
          single-line
          persistent-hint
          label="Número"
          class="qj-input"
          hint="Ex: (41) 99999-9999"
          prepend-inner-icon="fas fa-mobile-alt"
          v-model="form.cellphone"
          v-mask="'(##) #####-####'"
          :rules="rules.phone"
          @keyup.enter="submit()"
          autocomplete="tel-national"
        ></v-text-field>
      </v-col>
      <!-- Checkbox Whatsapp -->
      <v-col cols="12" class="pt-0">
        <v-checkbox
          hide-details
          class="qj-checkbox pt-0 mt-0 qj-checkbox-whatsapp"
          v-model="form.whatsapp"
        >
          <template v-slot:label>
            <div class="qj-checkbox-label">
              Utilizo este número para contato via WhatsApp
              <img
                style="vertical-align: middle"
                src="@/assets/img/midias/whatsapp.svg"
                alt="icone do whatsapp"
                width="20"
              />
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12">
        <div
          style="
            padding: 8px;
            background: #f6f6f6;
            border-radius: 7px;
            margin-bottom: 90px;
          "
        >
          <v-checkbox
            id="accept__policies__link"
            hide-details
            class="qj-checkbox pt-0 mt-0 qj-checkbox-terms-of-use"
            v-model="checkbox"
          >
            <template v-slot:label>
              <div class="qj-checkbox-label">
                Declaro que li e concordo com os
                <a
                  href="javascript:void(0);"
                  class="qj-link qj-color-secondary"
                  @click="
                    dialogTermsOfUse = true;
                    checkbox = !checkbox;
                  "
                  >Termos de Uso</a
                >
                e
                <a
                  href="javascript:void(0);"
                  class="qj-link qj-color-secondary"
                  @click="
                    dialogPolicy = true;
                    checkbox = !checkbox;
                  "
                  >Política de Privacidade.</a
                >
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer
      type="next"
      :disabled="disabledBtnFooter"
      :loading="loading"
      @send="submit"
      view="cadastro"
    />
    <!-- Snackbar error -->
    <v-snackbar v-model="snackbar">
      Não foi possível enviar seus dados. Tente novamente.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Dialog Contract Clauses -->
    <DialogTermsOfUse
      v-if="privacyPolicy"
      :footer="false"
      :dialogTermsOfUse="dialogTermsOfUse"
      :url="privacyPolicy.terms_of_use_url"
      @close="dialogTermsOfUse = false"
    />
    <!-- Dialog política de privacidade -->
    <DialogPolicy
      v-if="privacyPolicy"
      :footer="false"
      :dialogPolicy="dialogPolicy"
      :url="privacyPolicy.privacy_policy_url"
      @close="dialogPolicy = false"
    />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import util from "@/assets/js/util";
import rules from "@/assets/js/rules";
import DialogPolicy from "@/components/DialogPolicy";
import DialogTermsOfUse from "@/components/DialogTermsOfUse";
import servicePrivacyPolicy from "@/services/privacy_policy";

export default {
  components: {
    DialogPolicy,
    DialogTermsOfUse,
  },
  data: () => ({
    rules: rules,
    dialog: false,
    loading: false,
    snackbar: false,
    checkbox: false,
    dialogPolicy: false,
    dialogTermsOfUse: false,
    privacyPolicy: null,
    form: {
      name: "",
      email: "",
      cellphone: "",
      debit: null,
      document: null,
      whatsapp: false,
      _hdoc: "",
    },
  }),
  created() {
    this.form._hdoc = this.$route.query._hdoc || "";
    this.ActionsSetHdoc(this.form._hdoc);

    this.loadPrivacyPolicy();

    // Verifica se houve alteração nos Termos e Uso e na Política de Privacidade
    this.unwatchConfigurations = this.$store.watch(
      () => store.getters["negotiation/getConfiguration"],
      () => this.loadPrivacyPolicy()
    );
  },
  beforeDestroy() {
    this.unwatchConfigurations();
  },
  methods: {
    ...mapActions("opportunity", ["ActionsCreate", "ActionsSetHdoc"]),
    submit() {
      if (this.$refs.form.validate() && this.checkbox) {
        console.debug("Criando oportunidade.");

        this.loading = true;

        let payload = {
          opportunity: this.prepareForm(),
          useTermsId: util.getConfig()["current_privacy_policy"],
        };

        this.ActionsCreate(payload)
          .then(() => {
            console.info("Oportunidade criada.");
            this.$router
              .push({ name: "OpportunityVerification" })
              .catch((failure) => {
                this.skipIfNavigationFailureIsGeneric(failure)
              });
          })
          .catch((error) => {
            console.error("Não foi possível criar a oportunidade.", error);
            this.loading = false;
            this.snackbar = true;
          });
      }
    },
    prepareForm() {
      return {
        name: this.form.name || "",
        email: this.form.email || "",
        cellphone: util.justNumbers(this.form.cellphone) || "",
        debit: this.form.debitl || null,
        document: this.form.documentl || null,
        whatsapp: this.form.whatsapp || false,
        _hdoc: this.form._hdoc || "",
      };
    },

    /**
     * Consulta os Termos de Uso e a Política de Privacidade.
     */
    loadPrivacyPolicy() {
      let privacyPolicyId = util.getConfigValue("current_privacy_policy");

      if (!privacyPolicyId) {
        return;
      }

      console.debug("Política de privacidade:", privacyPolicyId);

      servicePrivacyPolicy
        .consult(privacyPolicyId)
        .then((res) => {
          this.privacyPolicy = res.data;
        })
        .catch((error) => {
          console.error(
            "Não foi possivel busca a política de privacidade.",
            error
          );
        });
    },
  },
  computed: {
    disabledBtnFooter() {
      return this.loading || !this.checkbox;
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-checkbox-whatsapp {
  margin-left: 8px;

  .qj-checkbox-label {
    margin-left: 8px;
  }
}

.qj-checkbox-terms-of-use {
  .qj-checkbox-label {
    margin-left: 8px;
  }
}
</style>
