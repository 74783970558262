<template>
  <v-form ref="form" lazy-validation>
    <!-- Body -->
    <v-row class="qj-row-main">
      <!-- Title -->
      <v-col cols="12" class="pt-4 pb-1">
        <p class="qj-p">
          Nos informe o valor da sua dívida e selecione a empresa credora logo
          abaixo:
        </p>
      </v-col>
      <!-- Input debt -->
      <v-col cols="12" class="pb-0">
        <p class="mb-1 qj-p">Qual o valor da sua dívida?</p>
        <v-text-field
          inputmode="decimal"
          dense
          outlined
          required
          single-line
          prefix="R$"
          class="qj-input"
          autocomplete="off"
          v-money="money"
          v-model="form.debt"
          :rules="rules.debt"
          @keyup.enter="submit()"
        ></v-text-field>
      </v-col>
      <!-- Select creditor -->
      <v-col cols="12" class="pt-0 pb-0">
        <p class="mb-1 qj-p">
          Em qual empresa credora você possui esta dívida?
        </p>
        <v-select
          dense
          outlined
          required
          single-line
          class="qj-input"
          label="Selecione uma empresa credora"
          v-model="form.creditor"
          :items="items"
          :rules="rules.creditor"
          @keyup.enter="submit()"
        >
          <!-- :rules="rules.creditor" -->
          <template v-slot:item="{ item }">
            <v-radio-group
              hide-details
              v-model="form.creditor"
              class="qj-select"
            >
              <v-radio
                class="qj-color-primary qj-select-creditor"
                hide-details
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
          </template>
        </v-select>
      </v-col>
      <!-- Input name creditor -->
      <v-fade-transition>
        <v-col cols="12" class="pt-0 pb-0" v-show="form.creditor == 'Outro'">
          <v-text-field
            dense
            outlined
            single-line
            class="qj-input"
            autocomplete="off"
            label="Nome da empresa credora"
            v-model="form.nameCreditor"
            :required="form.creditor == 'Outro'"
            :rules="form.creditor == 'Outro' ? rules.name : []"
            @keyup.enter="submit()"
          ></v-text-field>
        </v-col>
      </v-fade-transition>
    </v-row>
    <!-- footer -->
    <qj-footer
      type="next"
      view="cadastro/informacoes"
      :loading="loading"
      :disabled="loading"
      @send="submit"
    />
    <!-- Dialog loading debit -->
    <DialogLoadingDebts :dialogLoadingDebts="dialog" />
  </v-form>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import environment from "@/assets/js/environment";
import rules from "@/assets/js/rules";
import DialogLoadingDebts from "@/views/authentication/components/modals/DialogLoadingDebts";

const MAX_TRY = 8;

export default {
  components: {
    DialogLoadingDebts,
  },
  data: () => ({
    rules: rules,
    client: store.getters["opportunity/getClient"],
    dialog: false,
    loading: false,
    dialogHelp: false,
    try: 0,
    items: [
      "Santander",
      "Itaú",
      "Banco do Brasil",
      "Bradesco",
      "Caixa Econômica",
      "Outro",
    ],
    form: {
      debt: "0,00",
      creditor: "",
      nameCreditor: "",
    },
    money: {
      decimal: ",",
      thousands: ".",
      precision: 2,
    },
  }),
  created() {
    if (!this.client.id) {
      window.location = environment.landingUrl;
    }
  },
  methods: {
    ...mapActions("opportunity", ["ActionsSave"]),
    ...mapActions("authentication", ["loginOpportunity"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.ActionsSave(this.prepareFormSave())
          .then(() => {
            this.client = store.getters["opportunity/getClient"];
            this.logar();
          })
          .catch(() => {
            this.loading = false;
            this.$emit("snackbar", {
              snackbar: true,
              text: "Não foi possível enviar seus dados. Tente novamente.",
            });
          });
      }
    },
    prepareForm() {
      return {
        task_id: store.getters["opportunity/getTaskId"] || "",
        _hdoc: store.getters["opportunity/getHdoc"] || "",
      };
    },
    prepareFormSave() {
      return {
        id: this.client.id,
        data: {
          creditor:
            this.form.creditor != "Outro"
              ? this.form.creditor
              : this.form.nameCreditor,
          debit: this.form.debt != "0,00" ? this.form.debt : "",
        },
      };
    },
    logar() {
      if (this.try < MAX_TRY) {
        this.try++;
        this.loginOpportunity({payload: this.prepareForm(), vm: this})
          .then(({ data }) => {
            if (data.status != "DONE") {
              setTimeout(() => this.logar(), 1000);
            } else {
              if (data.error) {
                console.error(data.error);
                this.loading = false;
                this.$emit("snackbar", {
                  snackbar: true,
                  text: "Não foi possível enviar seus dados. Tente novamente.",
                });
                return;
              }
              this.dialog = true;
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
            this.$emit("snackbar", {
              snackbar: true,
              text: "Não foi possível enviar seus dados. Tente novamente.",
            });
          });
      } else {
        this.$router.push({ name: "NegotiationNoDeals" }).catch((failure) => {
          if (this.isNavigationFailure(failure)) {
            console.error(failure);
          }
        });
      }
    },
  },
};
</script>

<style>
.v-list {
  padding: 0;
}
.v-menu__content {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  /* overflow: hidden; */
  margin-top: 40px;
  padding: 5px 0;
}
.v-list--dense .v-list-item {
  padding: 0 10px;
  min-height: 30px;
}
.v-application .primary--text {
  color: var(--primary) !important;
  caret-color: var(--primary) !important;
}
.theme--light.v-icon {
  color: var(--primary);
}
.v-list-item--link:before {
  background-color: transparent !important;
}

.qj-select {
  margin: 0;
  padding: 0;
}
.qj-select label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px !important;
  color: #000000 !important;
}
.qj-select .v-input__slot {
  margin: 0;
}

.qj-select-creditor .v-label {
  margin-left: 8px;
}
</style>
