<template>
  <v-container class="px-0 pt-1">
    <v-row>
      <v-col cols="12" class="pt-0">
        <h1 class="qj-h1 mb-3" v-if="userInfo.customer_type == 'PERSONAL'">
          Olá {{ userInfo.name }},
        </h1>
        <div v-else>
          <h1 class="qj-h1 mb-3">Olá, tudo bem?</h1>
          <p class="greetings-company">Confira as propostas para a empresa</p>
          <p class="company-name">
            {{ capitalizeName(userInfo.name) }}
          </p>
        </div>
        <p class="mb-0 qj-p">
          Para aproveitar as ofertas disponíveis para você, precisamos confirmar
          seu <b>{{ userInfo.customer_type == "PERSONAL" ? "CPF" : "CNPJ" }}</b
          >. Complete os números para prosseguir.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-2 flex-content">
        <div class="d-flex align-center" 
          :class="userInfo.customer_type == 'PERSONAL' ? 'cpf-container' : 'cnpj-container'">
          <v-text-field
            dense
            outlined
            required
            single-line
            type="text"
            ref="inputCpf"
            autocomplete="off"
            inputmode="numeric"
            class="qj-input qj-bold qj-input-center mr-2"
            prepend-inner-icon="far fa-user"
            v-mask="setInputMask()"
            :placeholder="
              userInfo.customer_type == 'PERSONAL'
                ? '___.___-__'
                : '___.___/____-__'
            "
            v-model="cpf"
            :prefix="
              userInfo.customer_type == 'PERSONAL'
                ? `${userInfo.document}.`
                : userInfo.document?.[0] +
                  userInfo.document[1] +
                  '.' +
                  (userInfo.document[2] ?? '')
            "
            @keyup="send"
            hide-details
            data-hj-allow
          ></v-text-field>
          <button @click="dialogWhyConfirm = true">
            <v-icon>far fa-question-circle</v-icon>
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="error" cols="12" class="pa-0 flex-content centered">
        <div v-show="error" class="invalid-CPF-message">
          {{ userInfo.customer_type == "PERSONAL" ? "CPF" : "CNPJ" }} é
          inválido. <br />Verifique e tente novamente.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="mx-auto pt-2 justify-center">
        <v-btn
          class="rounded-lg qj-btn btn-cpf qj-bg-primary"
          :loading="loading"
          :disabled="loading || !isValidDocument"
          @click="send"
        >
          Prosseguir
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: 80px !important">
      <v-col cols="12" class="pt-6 text-center">
        <p class="mb-0 qj-p px-3">
          Com a <b>QuiteJá</b> você pode reestruturar sua vida financeira!
          Negociamos dívidas de forma <b>rápida, segura e descomplicada.</b>
        </p>
      </v-col>
    </v-row>
    <DialogWhyConfirm
      :dialogWhyConfirm="dialogWhyConfirm"
      @close="dialogWhyConfirm = false"
    />
    <!-- Footer -->
    <v-row class="qj-footer-abs">
      <v-col class="align-self-end">
        <qj-footer type="notMyCPF" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CustomerType } from "@/constants";
import { createTagManagerEvent } from "@/utils";
import util from "@/assets/js/util";
import DialogWhyConfirm from "../modals/DialogWhyConfirm.vue";

export default {
  props: ["userInfo", "error", "loading"],
  components: {
    DialogWhyConfirm,
  },
  data: () => ({
    dialogWhyConfirm: false,
    dialog: false,
    cpf: "",
  }),
  created() {
    if (this.$route.query.document)
      this.cpf = this.$route.query.document.substring(3);
    setTimeout(() => {
      if (this.$refs.inputCpf) {
        this.$refs.inputCpf?.focus();
      }
    }, 100);
  },
  mounted() {
    this.makeCarouselSetup();
  },
  methods: {
    clearChallenge() {
      this.cpf = "";
    },
    makeCarouselSetup() {
      var carouselContainer = document.querySelector(".carousel-container");
      var carouselTrack = document.querySelector(".carousel-track");
      var initialPosition = null;
      var isMouseMoving = false;
      var transform = 0;
      var lastPageX = 0;
      var transformValue = 0;

      var gestureStart = (e) => {
        if (!e) return;

        if (!carouselTrack) {
          return;
        }

        initialPosition = e.pageX;
        isMouseMoving = true;
        var transformationMatrix = window
          .getComputedStyle(carouselTrack)
          .getPropertyValue("transform");
        if (
          transformationMatrix &&
          transformationMatrix != "" &&
          transformationMatrix != "none"
        )
          transform = parseInt(transformationMatrix.split(",")[4].trim());
      };

      var gestureMove = (e) => {
        if (!e) return;

        if (!carouselTrack) {
          return;
        }

        if (isMouseMoving) {
          var diff = e.pageX - initialPosition;
          let isMovingToRight = e.pageX - lastPageX > 0;
          if (isMovingToRight) {
            if (transformValue > 0) return;
          } else if (carouselContainer) {
            let maxMove =
              carouselContainer.clientWidth - carouselTrack.clientWidth;
            if (Math.abs(transformValue) > Math.abs(maxMove) + 60) return;
          }
          transformValue = transform + diff;
          carouselTrack.style.transform = `translateX(${transformValue}px)`;
        }
        lastPageX = e.pageX;
      };

      var gestureEnd = (e) => {
        if (!e) return;
        isMouseMoving = false;
      };

      if (window.PointerEvent) {
        window.addEventListener("pointerdown", gestureStart);
        window.addEventListener("pointermove", gestureMove);
        window.addEventListener("pointerup", gestureEnd);
      } else {
        window.addEventListener("touchstart", gestureStart);
        window.addEventListener("pointermove", gestureMove);
        window.addEventListener("touchend", gestureEnd);
        window.addEventListener("mousedown", gestureStart);
        window.addEventListener("mousemove", gestureMove);
        window.addEventListener("mouseup", gestureEnd);
      }
    },
    async send() {
      if (!this.isValidDocument) return;
      createTagManagerEvent({
        event: "loginSubmittedDocument",
        customerType: this.customer_type,
      });
      let payload = {
        answer: this.getDocumentNumber(),
        type: "DOCUMENT",
      };

      if (this.$route.query.source_channel) {
        payload.source_channel = this.$route.query.source_channel;
      }
      this.$emit("validateChallenge", payload);
    },
    getDocumentNumber() {
      let cpfNumber = `${this.userInfo.document}.${this.cpf}`;
      return cpfNumber.replace(/-/g, "").replace(/\./g, "").replace(/\//g, "");
    },
    capitalizeName(nameToFormat) {
      if (typeof nameToFormat != "string" || nameToFormat.length <= 1) {
        return nameToFormat;
      }

      let lowercasedName = nameToFormat.toLowerCase();
      let capitalizedFirst = lowercasedName?.[0]?.toUpperCase();
      let rest = lowercasedName.slice(1);

      return capitalizedFirst + rest;
    },
    toggleDialogVisibility(status) {
      this.dialog = status;
    },
    setInputMask() {
      if (this.userInfo.customer_type == 'PERSONAL') {
        return '###.###-##';
      }
      else if (this.userInfo.document[2]) {
        return '##.###/####-##';
      }
      else {
        return '###.###/####-##';
      } 
    }
  },
  computed: {
    isValidDocument() {
      if (util.isEmpty(this.cpf)) {
        return false;
      }
      if (!this.userInfo || !this.userInfo.customer_type) {
        return this.cpf.length < 10 ? false : true;
      }
      let document = this.getDocumentNumber();
      let customerInfo = CustomerType[this.userInfo.customer_type];
      return document.length >= customerInfo.maxLength;
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-footer-abs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.cpf-container, .cnpj-container {
  display: flex;
  width: 100%;
}

.invalid-CPF-message {
  background: #ffdbdb;
  color: #ff5050;
  text-align: center;
  border-radius: 7px;
  font-weight: 800;
  padding: 6px 40px;
  font-size: 12px;
}

.qj-btn.qj-bg-secondary-light {
  border-radius: 0.5em;
  width: 13.5em;
}
.flex-content {
  display: inline-flex;
  justify-content: space-around;
}

.text-numbers-not-match {
  margin: -1em 3.5em 0 3.5em;
  text-align: center;
  font-weight: 400;
  line-height: 1em;
  font-size: 0.75em;
}

.text-clean-name {
  margin: 0 3em 0 3em;
  font-weight: 800;
  text-align: center;
  line-height: 1.2em;
}

.text-about-us {
  font-weight: 800;
}

.safe-message {
  font-size: 0.75em;
  margin-bottom: 0.5em;
  i {
    font-size: initial;
    margin-right: 0.5em;
  }
}

.video-container {
  background: #cafce9;
  max-width: 60em;
  padding: 1em;
  text-align: start;
  font-size: 0.85em;
  border-radius: 0.5em;
  line-height: 1.2em;
  .iframe-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: auto;
    pointer-events: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
  }
  .iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
  }
}

.carousel-container {
  width: 750px;
  height: 230px;
  position: relative;
  overflow: hidden;

  .carousel-track {
    position: absolute;
    display: inline-flex;
    touch-action: none;
    width: 715px;
    height: 240px;
    left: 8px;
    .carousel-item {
      width: 223px;
      min-width: 233px;
      height: 220px;
      background: #ffffff;
      border: 1px solid #f6f6f6;
      box-sizing: border-box;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      margin-left: 1em;

      .item-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        div {
          display: block;
        }
        .title-container {
          position: relative;
          text-align: center;
          div {
            font-style: normal;
            font-weight: bold;
            font-size: 100px;
            line-height: 122px;
            color: #cafce9;
          }
          b {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #212121;
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
          }
        }
        .text-container {
          line-height: 1em;
          align-items: center;
          padding: 0 1em;
          text-align: center;
          span {
            font-size: 0.8em;
          }
        }
        .image-container {
          padding-top: 1em;
          display: flex;
          justify-content: space-around;
          height: 60%;
          .circle {
            border-radius: 1em;
            background: #f6f6f6;
            width: 6em;
            height: 6em;
            position: absolute;
            border-radius: 100%;
            top: 0.5em;
          }
          img {
            position: relative;
            width: fit-content;
            height: fit-content;
          }
          .img1 {
            top: 0.4em;
            left: 0.3em;
          }
          .img2 {
            top: 0.8em;
            left: 0.5em;
          }
          .img3 {
            left: 0.2em;
          }
        }
      }
    }
  }
}

.v-text-field__prefix {
  color: black !important;
}

.greetings-company {
  font-size: 14px;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.25;
}

.greetings-company,
.company-name {
  margin-bottom: 10px;
}
.v-icon {
  color: #000000;
}
</style>
