<template>
  <div>
    <v-list two-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Versão</v-list-item-title>
          <v-list-item-subtitle>{{ version }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Cliente autenticado</v-list-item-title>

          <v-list-item-subtitle v-if="user">
            <strong>{{ user.name }}</strong>
            <br>
            {{ user.document | customerDocument }}
          </v-list-item-subtitle>

          <v-list-item-subtitle v-else>{{ "-" }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-tooltip v-if="user" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-action v-bind="attrs" v-on="on">
              <v-btn icon @click="logoutFromApp">
                <v-icon color="grey lighten-1">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
          <span>Deslogar</span>
        </v-tooltip>
      </v-list-item>
    </v-list>

    <div v-if="deferredPrompt" class="text-center">
      <v-btn class="qj-bg-primary" @click="install()">
        Instalar
      </v-btn>
    </div>

    <div v-if="user" class="text-center mt-4">
      <v-btn class="qj-bg-primary" @click="copyAuthenticationToken()">
        Copiar token
      </v-btn>
    </div>

    <div class="text-center mt-4">
      <v-btn class="qj-bg-primary" @click="clearCacheStorage()">
        Limpar cache
      </v-btn>
    </div>
  </div>
</template>

<script>
import { version } from "@/../package.json";

import environment from "@/assets/js/environment";
import { mapActions } from "vuex";

export default {
  data: () => ({
    deferredPrompt: null,
    user: null,
    version: version,
  }),
  created() {
    let token = this.$cookies.get("QUITEJA_DEAL_TOKEN");

    if (!token) {
      return;
    }

    this.getAuthenticatedUser({token: token})
      .then((res) => {
        this.user = res.data;
      })
      .catch(() => {
        this.user = null;
      });

    // Captura o evento de instalação do PWA
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
  },
  methods: {
    ...mapActions("authentication", [
      "getAuthenticatedUser",
      "logout",
    ]),
    logoutFromApp() {
      this.logout().then(() => {
        this.user = null;
      });
    },
    /**
     * Instala o PWA.
     */
    async install() {
      this.deferredPrompt.prompt();

      const { outcome } = await this.deferredPrompt.userChoice;
      console.info("Resposta da instalação do PWA:", outcome);

      this.deferredPrompt = null;
    },

    /**
     * Limpa todos os cache storages.
     */
    clearCacheStorage() {
      console.debug("Limpando caches.");

      caches.keys().then(function(names) {
        for (let name of names) {
          console.debug("Limpando cache storage:", name);
          caches.delete(name);
        }
      });
    },

    /**
     * Copia o token de autenticação do usuário logado.
     */
    copyAuthenticationToken() {
      let token = this.$cookies.get('QUITEJA_DEAL_TOKEN');

      navigator.clipboard.writeText(token).then(() => {
        alert('Token copiado');
      }, () => {
        alert('Não foi possível copiar o token');
      });
    },
  },
};
</script>
