<template>
  <div class="page-error d-flex flex-column">
    <div class="image mx-auto mt-10">
      <img src="@/assets/img/errors/500.png" alt="Erro" />
    </div>

    <div class="mt-5 text-left mx-2">
      <h1 class="text-center">Ops!</h1>
      <span class="mt-4">
        No momento não é possível visualizar as suas propostas
        {{ creditorAndSufix }}.
      </span>
      <p class="mt-4">
        Quando estiver disponível,
        <strong>escolha a melhor forma de ser avisado:</strong>
      </p>
    </div>

    <div class="radios">
      <v-radio-group v-model="selectedContact">
        <v-radio v-for="(contactType, index) in contactTypes" color="#00E58D" class="radio-container"
          active-class="active-radio" :key="index" :label="contactType.label" :value="contactType.type"
          @click="dialog = !dialog"></v-radio>
      </v-radio-group>
    </div>
    <div class="">
      <v-dialog persistent v-model="dialog" width="300">
        <v-card class="text-center" v-if="selectedContactType">
          <v-fade-transition hide-on-leave>
            <div v-if="!sended" key="0">
              <div class="close-btn"></div>
              <v-btn class="close-btn" @click="dialog = !dialog" absolute small width="23" height="23" color="black"
                icon></v-btn>
              <v-card-title class="justify-center">
                <img src="@/assets/img/errors/contact.png" />
              </v-card-title>
              <v-card-text>
                <span class="dialog-title mt-1">Informações para contato</span>
                <p class="mb-6 dialog-subtitle">
                  <span>{{ selectedContactType.textfield.subtitle }}</span>
                </p>
                <v-form ref="form" @submit.prevent="send">
                  <v-text-field color="black" v-model="contact"
                    v-mask="selectedContact == 'MAIL' ? '' : '(##) #####-####'" :rules="contactRule"
                    :name="selectedContactType.textfield.type" :type="selectedContactType.textfield.type" outlined dense
                    validate-on-blur :placeholder="selectedContactType.textfield.placeholder"
                    :prepend-inner-icon="selectedContactType.textfield.icon"></v-text-field>
                </v-form>
                <v-btn width="90%" :loading="loading" :disabled="loading"
                  class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal" @click="send">Enviar</v-btn>
              </v-card-text>
            </div>
            <div v-else key="1">
              <v-card-title class="justify-center">
                <img src="@/assets/img/svg/success.svg" />
              </v-card-title>
              <v-card-text>
                <span class="dialog-title mt-1">Enviado com sucesso</span>
                <p class="mb-6 dialog-subtitle">
                  <span>Te avisaremos assim que sua <br />
                    proposta estiver disponível</span>
                </p>

                <v-btn width="90%" :loading="loading" class="rounded-lg qj-btn qj-bg-primary btn__accept__proposal"
                  @click="redirect">voltar</v-btn>
              </v-card-text>
            </div>
          </v-fade-transition>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Sentry from "@sentry/browser";
import { ContactMeError } from "@/errors";
import rules from "@/assets/js/rules";
import util from "@/assets/js/util";

export default {
  data: () => ({
    selectedContact: null,
    dialog: false,
    contact: null,
    loading: false,
    sended: false,
    contactTypes: [
      {
        type: "WHATSAPP",
        label: "Via Whatsapp",
        textfield: {
          subtitle: "Preencha abaixo o seu WhatsApp:",
          icon: "mdi-whatsapp",
          placeholder: "WhatsApp",
          type: "tel",
        },
      },
      {
        type: "MAIL",
        label: "Via E-mail",
        textfield: {
          subtitle: "Preencha abaixo o seu melhor e-mail:",
          icon: "mdi-email-outline",
          placeholder: "E-mail",
          type: "email",
        },
      },
      {
        type: "SMS",
        label: "Via SMS",
        textfield: {
          subtitle: "Preencha abaixo o seu celular:",
          icon: "mdi-cellphone",
          placeholder: "Celular",
          type: "tel",
        },
      },
    ],
  }),
  beforeRouteEnter(to, from, next) {
    if (from.name == "NegotiationDeal" && to.params.stay) {
      next();
      return;
    }
    next({ name: "Home" });
  },
  created() {
    this.$emit("loading", false);
  },
  methods: {
    ...mapActions("contact", ["generateRetry", "createContact", "setContactId"]),
    async sendContact() {
      let contact =
        this.selectedContact == "MAIL"
          ? this.contact
          : util.justNumbers(this.contact);

      if (this.userInfo.contacts?.some(({ value, status }) => value == contact && status === "CONFIRMED")) {
        this.setContactId(this.userInfo.contacts?.find(({ value }) => value == contact).id)
        return true;
      }

      let createContactPayload = {
        contacts: [`${contact}`],
        confirm: true,
      };

      return this.createContact(createContactPayload);
    },
    async contactMe() {
      let generateRetryPayload = {
        contact_id: this.getContactId,
        deal_id: this.selectedDeal.id,
      };

      if (this.selectedContact == "WHATSAPP") {
        generateRetryPayload['whatsapp'] = true
      }

      this.generateRetry(generateRetryPayload)
        .then(() => {
          this.sended = true;
        })
        .catch((error) => {
          Sentry.captureException(
            new ContactMeError({
              axiosError: error,
              message: "Não foi possivel guardar o contato para notificar.",
            })
          );

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async send() {
      const validContact = this.$refs.form.validate();
      if (!validContact) return;
      this.loading = true;

      await this.sendContact();
      this.contactMe();
    },
    redirect() {
      const hasMoreDeals = this.deals.some((deal) => {
        return deal.id != this.selectedDeal.id;
      });
      if (hasMoreDeals) {
        this.$router.replace({ name: "Home" }).catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure)
          });
      } else {
        window.location.href = "https://quiteja.com.br";
      }
    },
  },
  computed: {
    ...mapGetters("contact", ["getContactId"]),
    ...mapGetters("negotiation", {
      selectedDeal: "getSelectedDeal",
      deals: "getDeals",
      userInfo: "getMe",
    }),
    contactRule() {
      if (this.selectedContact == "MAIL") {
        return rules.email;
      }
      return [...rules.phone, rules.required];
    },
    selectedContactType() {
      return this.contactTypes.find((contactType) => {
        return contactType.type == this.selectedContact;
      });
    },
    creditorAndSufix() {
      let creditor = this.selectedDeal.creditor;
      let prefix = creditor.profile.gender == "MALE" ? "do" : "da";
      return `${prefix} ${creditor.profile.short_name}`;
    },
  },
  watch: {
    dialog: {
      handler() {
        this.contact = "";
        this.loading = false;
        this.$refs?.form?.reset();
      },
    },
  },
};
</script>

<style lang="scss">
.close-btn {
  background-image: url(../../../assets/img/icons/close.png);
  right: 10px;
  top: 10px;
}

.dialog {
  &-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #000000;
  }

  &-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 115.9%;
    /* or 16px */
    text-align: center;
    color: #000000;
    margin-top: 14px;
  }
}

.radio-container {
  background: #f6f6f6;
  padding: 8px 9px;
  border-radius: 7px;

  .v-label {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 115.9% !important;
    /* or 16px */
    color: #000000 !important;
    margin-left: 9px;
  }
}
</style>
