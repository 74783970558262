<template>
  <div>
    <v-row class="qj-row-main content-container">
      <v-col cols="12" class="pt-6 welcome">
        <h1 class="qj-h2 pb-2" v-if="$route.query.modo == 'boleto'">
          Procurando informações do seu acordo?
        </h1>
        <h1 class="qj-h2 pb-2" v-else>Boas-vindas a QuiteJá!</h1>
        <p class="mb-0 qj-p">
          Nos informe seu
          <strong>CPF ou CNPJ completo</strong> e clique em prosseguir para
          {{
            $route.query.modo == "boleto"
              ? "acessar suas informações de pagamento."
              : "continuar com sua negociação."
          }}
        </p>
      </v-col>

      <v-col class="customer-type">
        <v-radio-group v-model="customerType" mandatory row hide-details>
          <v-radio
            class="qj-radio"
            :label="`Pessoa física`"
            :value="'PERSONAL'"
          ></v-radio>
          <v-radio
            class="qj-radio"
            :label="`Pessoa jurídica`"
            :value="'BUSINESS'"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="pt-2 flex-content">
        <div class="document-container">
          <v-text-field
            type="text"
            inputmode="numeric"
            dense
            hide-details
            outlined
            required
            single-line
            class="qj-input mr-2"
            prepend-inner-icon="far fa-user"
            v-mask="customerTypeInfo.mask"
            v-model="document"
            :placeholder="customerTypeInfo.placeholder"
            @keyup="send"
            data-hj-allow
            id="input__document__field"
          ></v-text-field>
        </div>
      </v-col>
      <v-alert
        :value="error"
        color="red"
        text
        transition="scale-transition"
        class="qj-alert-error text-center"
      >
        Documento inválido.
        <br />Verifique e tente novamente.
      </v-alert>
      <v-col cols="12" class="pt-2 flex-content centered text-center mb-90">
        <v-btn
          class="rounded-lg qj-btn btn-cpf qj-bg-primary grow"
          :loading="loading"
          :disabled="loading || !isValidDocument"
          @click="send"
        >
          Prosseguir
        </v-btn>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer class="qj-footer-abs" />
  </div>
</template>

<script>
import { CustomerType } from "@/constants";
import { createTagManagerEvent } from "@/utils";

export default {
  props: ["error", "loading"],
  data: () => ({
    document: "",
    customerType: "PERSONAL",
  }),
  methods: {
    send() {
      if (!this.isValidDocument) return;

      if (this.loading) {
        return;
      }

      createTagManagerEvent({
        event: "loginSubmittedDocument",
        customerType: this.customerType,
      });

      this.$emit("send", {
        document: this.documentDigits,
        auth_type: "CUSTOMER",
      });
    },
  },
  computed: {
    isValidDocument() {
      return this.documentDigits.length >= this.customerTypeInfo.maxLength;
    },
    documentDigits() {
      return this.document
        .replace(/-/g, "")
        .replace(/\./g, "")
        .replace(/\//g, "");
    },
    customerTypeInfo() {
      return CustomerType[this.customerType];
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-90 {
  margin-bottom: 90px;
}
.qj-footer-abs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.qj-row-main {
  margin-bottom: 0;
  min-height: auto;
}

.welcome {
  margin-bottom: 16px;
}

.customer-type {
  padding: 0;

  .v-input {
    margin-top: 0;
    padding: 0 12px;

    :deep(label) {
      font-size: 14px;
      margin-left: 8px;
      @media (max-width: 320px) {
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
}
</style>

<style lang="scss">
.customer-type {
  @media (max-width: 320px) {
    .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-right: 8px;
    }
  }
}
</style>
