<template>
    <v-dialog v-model="dialogWhyConfirm" persistent max-width="372">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3 mb-2">Por que confirmar meu CPF?</h3>
          <img src="@/assets/img/svg/aguarde.svg" />
        </v-card-title>
        <v-card-text>
          <p class="qj-card-text">
            Esse é um passo importante para sua segurança, assim podemos
            confirmar sua identidade e garantir que somente você tenha acesso as
            suas informações. <br>
            É através do seu login que a QuiteJá busca ofertas disponíveis nos
            credores para você. <br>
            Não se preocupe, não compartilhamos seus dados!
          </p>
          <v-btn
            color="#00e58d"
            class="rounded-lg qj-btn v-btn mx-auto px-10"
            @click="$emit('close')"
            >Entendi</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    dialogWhyConfirm: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-h3 {
  word-break: break-word;
}
</style>
