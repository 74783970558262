<template>
  <div>
    <div class="d-flex justify-center" id="sms__code__inputs">
      <input
        autofocus
        min="0"
        max="9999"
        maxlength="4"
        ref="passcodeNumber"
        v-mask="'####'"
        placeholder="0000"
        class="passcodeNumber data-hj-allow"
        inputmode="numeric"
        autocomplete="one-time-code"
        v-model="passcodeNumber"
        @input="checkCode()"
        @keydown="($e) => keyPressed($e)"
        data-hj-allow
        outlined
        hide-details="auto"
        :loading="isLoading"
        :disabled="isLoading"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import util from "@/assets/js/util";
import environment from "@/assets/js/environment";
import authenticationService from "@/services/authentication";
import { createTagManagerEvent } from "@/utils";

export default {
  props: ["type"],
  data: () => ({
    isLoading: false,
    alert: false,
    keyType: "",
    startTimer: "",
    passcodeNumber: null,
  }),
  created() {
    this.startTimer = Math.round(performance.now());
    // Busca o código de negociação (passcode) recebido por SMS
    if (environment.useOtpSms && "OTPCredential" in window) {
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
        })
        .then((otp) => this.otpSmsCallback(otp))
        .catch((err) => {
          console.error("Não foi possível receber o código OTP", err);
        });
    }
  },
  mounted() {
    setTimeout(() => {
       this.$refs.passcodeNumber?.focus();
    }, 500);
  },
  watch: {},
  methods: {
    ...mapActions("opportunity", ["ActionsSetTaskId"]),
    ...mapActions("authentication", ["validateChallenge"]),
    async checkCode(useOtp = false) {
      if (this.passcodeNumber && this.passcodeNumber?.length == 4 && !this.isLoading) {
        this.$refs.passcodeNumber.blur();
        const onlyNumbers = /^\d+$/.test(this.passcodeNumber)
        if (!onlyNumbers) return
        this.clearAlerts();
        createTagManagerEvent({
          event: "loginSubmittedVerificationCode",
          waitingTime: (Math.round(performance.now()) - this.startTimer) / 1000,
          usedWebOtp: useOtp,
        });
        this.isLoading = true;
        switch (this.type) {
          case "OPPORTUNITY":
            this.isOpportunity();
            break;
          case "PASSCODE":
            this.isPasscode();
            break;
        }
      }
    },
    isOpportunity() {
      this.$emit("sending", true);
      authenticationService
        .confirm(this.prepareForm())
        .then((res) => {
          this.isLoading = true;
          if (res.data)
            if (res.data.verification == "fail") {
              this.$emit("alert", true);
            } else {
              this.ActionsSetTaskId(res.data.task_id);
              this.$emit("alert", false);
              this.$emit("validation", true);
            }
        })
        .catch(() => {
          this.$emit("alert", true);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.$emit("sending", false);
          this.isLoading = false;
        });
    },
    isPasscode() {
      this.$emit("sending", true);
      let payload = this.prepareForm();
      payload.token = store.getters["authentication/getTokenPhone"];
      if (typeof this.$route.params.oldParams !== "undefined") {
        let code = `${this.passcodeNumber}` || "";
        payload = this.$route.params.oldParams;
        payload.answer = code;
      }
      this.validateChallenge(payload)
        .then(() => {
          this.alert = false;
          this.isLoading = true;
          this.$emit("validation", { validation: true, payload: payload });
        })
        .catch((error) => {
          // Erro 401: challenge inválido
          if (error.response && error.response.status == 401) {
            this.$emit("alert", true);
            return;
          }

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.$emit("sending", false);
          setTimeout(() => {this.isLoading = false}, 1000)
        });
    },
    prepareForm() {
      let code = this.passcodeNumber + "" || "";
      switch (this.type) {
        case "OPPORTUNITY":
          return {
            code: code,
            document: store.getters["opportunity/getClient"].document || "",
            _hdoc: store.getters["opportunity/getHdoc"] || "",
          };
        case "PASSCODE":
          return {
            answer: code,
            type: "CONTACT_PASSCODE",
            token: store.getters["authentication/getToken"],
          };
        default:
          return {};
      }
    },
    keyPressed(e) {
      if (this.passcodeNumber?.length > 3 && this.isKeyBackspace(e.key)) {
        this.clearAlerts();
      }
    },
    isKeyBackspace(key) {
      return key == "Backspace" ? true : false;
    },
    clearAlerts() {
      this.$emit("alert", false);
    },
    /**
     * Recebe o resultado do OTP recebido por SMS.
     */
    otpSmsCallback(otp) {
      if (!otp || !otp.code) {
        return;
      }

      console.debug("Código OTP recebido:", otp.code);

      if (util.isEmpty(otp.code) && otp.code?.length < 4) {
        console.debug("O código deve ter 4 dígitos");
        return;
      }

      this.passcodeNumber = otp.code;

      this.checkCode(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  padding-bottom: 10px;
}
.verification {
  width: 50px;
  height: 55px;
  background: #f0f0f0;
  border-radius: 7px;
  margin: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification::-webkit-outer-spin-button,
.verification::-webkit-inner-spin-button {
  margin: 0;
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.verification:focus {
  background: #cafce9;
  box-shadow: 0 0 0 0 !important;
  border: 0 none !important;
  outline: 0 !important;
}

.verification:focus::placeholder {
  color: transparent;
}

.passcodeNumber {
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  padding: 8px 0 8px 25px;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 26px;
  margin: 0 auto;
  outline: none;
  border-color: #212121;
  border-style: solid;
  border-width: thin;

  background: url("../assets/img/icons/shield-check-outline.svg") no-repeat left;
  background-size: 20px;
  background-position-x: 12px;

  &:focus,
  &:active,
  &:focus-visible,
  &:target,
  &::selection {
    border-color: #00e58d;
  }
}
</style>
