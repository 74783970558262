<template>
  <div class="content-container">
    <div class="start-content fill-height">
      <DocumentScreen
        v-if="document.show"
        :loading="loading"
        :error="document.error"
        @send="sendDocument"
      ></DocumentScreen>
      <SmsLinkScreen
        v-if="userInfo && smsLink.show"
        ref="SmsLink"
        :loading="loading"
        :userInfo="userInfo"
        :error="smsLink.error"
        @finishedLoading="loading = false"
        @validateChallenge="validate"
      ></SmsLinkScreen>
    </div>
    <DialogLoadingDebts
      :dialogLoadingDebts="dialog"
      @closeDialogLoadingDebts="close"
      @policy="$emit('policy', $event)"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapMutations } from "vuex";
import Authentication from "./authentication";
import environment from "@/assets/js/environment";
import DocumentScreen from "../components/screens/Document";
import SmsLinkScreen from "../components/screens/SmsLink";
import DialogLoadingDebts from "../components/modals/DialogLoadingDebts";

export default {
  components: {
    DocumentScreen,
    SmsLinkScreen,
    DialogLoadingDebts,
  },
  data: () => ({
    userInfo: null,
    dialog: false,
    loading: false,
    query: {},
    document: {
      show: false,
      error: false,
    },
    smsLink: {
      show: false,
      error: false,
    },
  }),
  beforeCreate() {
    this.$emit("loading", true);
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "Authentication" && !from.meta.requiresAuth) {
      next();
      return;
    }
    next({ name: "Home" });
  },
  created() {
    this.query = this.$route.query;

    this.saveGoogleAnalyticsParams();
    this.start();
    setTimeout(() => this.$emit("loading", false), 300);
    this.verifyExclusiveCreditorOnUrl(this.$route);
  },
  methods: {
    ...mapActions("authentication", [
      "login",
      "loginDocument",
      "validateChallenge",
      "getAuthenticatedUser",
      "getAuthenticatedUserId",
      "removeAuthenticatedUserId",
    ]),
    ...mapMutations("authentication", {
      setAnalytics: "setAnalytics",
      setUserInfo: "setUserInfo",
      setFomentation: "setFomentation",
      setChallengeResultObject: "setChallengeResultObject",
    }),
    ...mapActions("authentication", {
      setCookie: "setAuthTokenToCookie",
    }),
    ...mapActions("brokenDeal", ["verifyExclusiveCreditorOnUrl"]),
    start() {
      if (
        this.checkLinkOrCustomer() ||
        this.checkOpportunity() ||
        this.checkToken()
      )
        return;

      this.document.show = true;
    },
    checkToken({alreadyLogged = true} = {}) {
      let token = this.$cookies.get("QUITEJA_DEAL_TOKEN");
      if (token) {
        this.getAuthenticatedUser({token: token, alreadyLogged: alreadyLogged})
          .then((res) => {
            this.setUserInfo(res.data);
            this.dialog = true;
          })
          .catch((error) => {
            console.error('Não foi possível obter os dados do cliente autenticado.', error);

            this.removeAuthenticatedUserId();
            this.document.show = true;
          });

        return true;
      }
      return false;
    },
    checkOpportunity() {
      if (this.query._hdoc) {
        setTimeout(() => {
          this.$emit("loading", false);

          this.$router
            .replace({
              name: "OpportunityStart",
              query: { _hdoc: this.query._hdoc },
            })
            .catch((failure) => {
              this.skipIfNavigationFailureIsGeneric(failure);
            });
        }, 1000);

        return true;
      }
      return false;
    },
    checkLinkOrCustomer() {
      if (this.query.link || this.query.customer || this.query.hash) {
        this.setFomentation(this.query.fomentation == "true");
        
        this.login(this.payload())
          .then(async () => {
            let tokenFromAuth = this.$jwt.decode(
              store.getters["authentication/getToken"]
            );
            let tokenFromCookie = this.$cookies.get("QUITEJA_DEAL_TOKEN");
            let loggedUserId = await this.getAuthenticatedUserId();

            console.debug('Id do cliente da pré-autenticação:', tokenFromAuth.customer_id);
            console.debug('Id do cliente no cookie:', loggedUserId);

            if (tokenFromCookie && loggedUserId) {
              let userIdFromAuth = tokenFromAuth.customer_id;
              if (loggedUserId == userIdFromAuth) {
                console.debug('Cliente já está autenticado.');
                this.checkToken({alreadyLogged: true});
                return;
              } else {
                console.debug('Cliente autenticado é diferente do cliente da pré-autenticação.');
              }
            }
            
            this.setUserInfo(tokenFromAuth);
            this.userInfo = store.getters["authentication/getUserInfo"];
            this.setChallengeResultObject(tokenFromAuth);
            this.displayScreen();
          })
          .catch((error) => {
            console.error('Não foi possível fazer o login.', error);
            this.document.show = true;
          });

        return true;
      }
      return false;
    },
    payload() {
      if (this.query.customer) {
        return {
          data: {
            auth_type: "CUSTOMER",
            _hid: this.query.customer,
            _hdoc: this.query._hdoc,
          },
          token: "",
        };
      } else if (this.query.link) {
        return {
          data: {
            auth_type: "TRACK",
            track_id: this.query.link,
            source_channel: this.query.source_channel,
            change_contact: this.query.change_contact != 'false',
          },
          token: "",
        };
      } else if (this.query.hash) {
        return {
          data: {
            auth_type: "HASH",
            hash_id: this.query.hash,
            source_channel: this.query.source_channel,
            change_contact: this.query.change_contact != 'false',
          },
          token: "",
        };
      }
    },
    displayScreen() {
      if (this.query.customer) {
        this.$emit("loading", false);
        this.$router
          .push({
            name: "AuthLanding",
            params: {
              meta: { showMenu: false },
            },
          })
          .catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure);
          });
      } else if (this.query.link || this.query.hash) {
        this.smsLink.show = true;
        return;
      }
    },
    sendDocument(payload) {
      this.showError(false);
      this.loginViaDocument(payload);
    },
    loginViaDocument(payload) {
      this.loading = true;
      this.loginDocument(payload)
        .then(() => {
          this.challengeSuccess(payload);
        })
        .catch((error) => {
          // Erro 401: documento inválido
          if (error.response && error.response.status == 401) {
            this.showError();
            this.$vuetify.goTo(100);
          } else {
            this.$emit("snackbar", {
              snackbar: true,
              text: "Houve um erro. Tente novamente.",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    validate(payload) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.showError(false);
      payload.token = store.getters["authentication/getToken"];
      this.validateChallenge(payload)
        .then(() => {
          this.loading = false;
          this.challengeSuccess(payload);
        })
        .catch((error) => {
          this.$refs?.SmsLink?.clearChallenge();
          // Erro 401: challenge inválido
          if (error.response && error.response.status == 401) {
            this.showError();
            this.$vuetify.goTo(100);
          } else {
            this.$emit("snackbar", {
              snackbar: true,
              text: "Houve um erro. Tente novamente.",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    challengeSuccess(payload) {
      let resultTokenObject = this.$jwt.decode(
        store.getters["authentication/getToken"]
      );
      if (resultTokenObject.token_type == "AUTH") {
        this.setUserInfo(resultTokenObject.customer);
        this.setCookie({ payload: null, vm: this });

        this.createTagManagerEvent({ event: "Login" });

        this.dialog = true;
      }
      if (resultTokenObject.token_type == "PRE_AUTH") {
        if (this.document.show) {
          let resultTokenObject = this.$jwt.decode(
            store.getters["authentication/getToken"]
          );
          this.setUserInfo(resultTokenObject);
          this.userInfo = store.getters["authentication/getUserInfo"];
        }
        this.setChallengeResultObject(
          this.$jwt.decode(
            store.getters["authentication/getChallengeResultToken"]
          )
        );
        this.challenge(
          store.getters["authentication/getChallengeResultObject"].challenge,
          payload
        );
      }
    },
    challenge(type, params = {}) {
      if (type == "DOCUMENT") {
        this.document.show = true;
      } else {
        Authentication.resolveChallenge(
          type,
          params,
          this.$route.query.continue
        );
      }
    },
    close() {
      this.dialog = false;
      this.$router.replace({ name: "Deals" }).catch((failure) => {
        this.skipIfNavigationFailureIsGeneric(failure);
      });
    },
    showError(show = true) {
      if (this.document.show) this.document.error = show;
      if (this.smsLink.show) this.smsLink.error = show;
    },

    /**
     * Armazena os parâmetros do Google Analytics.
     * Os parâmetros serão usados quando uma proposta for confirmada.
     */
    saveGoogleAnalyticsParams() {
      let analytics = {
        campaign: this.$route.query["utm_campaign"],
        content: this.$route.query["utm_content"],
        medium: this.$route.query["utm_medium"],
        source: this.$route.query["utm_source"],
        term: this.$route.query["utm_term"],
        firing_id: this.$route.query["firing_id"]
      };

      if (this.$route.query["gclid"]) {
        // Anúncio do Google Ads
        analytics.medium = analytics.medium || "cpc";
        analytics.source = analytics.source || "google";
      }
      if (!analytics.medium && !analytics.source) {
        analytics = this.$cookies.get("last_analytics");
      }
      if (!analytics?.medium && !analytics?.source) {
        analytics = {
          source: "url_direct",
        };
      }
      this.setAnalytics(analytics);
      this.$cookies.set(
        "last_analytics",
        analytics,
        "1y",
        null,
        environment.authTokenCookieDomain
      )
    },
  },
};
</script>

<style></style>
