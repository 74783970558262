<template>
  <div class="text-center">
    <v-row class="qj-row-main">
      <v-col cols="12 pt-11 pb-4 qj-lh-0 image-container">
        <img src="@/assets/img/svg/phone.svg" alt="Código de verificação" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <h3 class="qj-h3 pb-3">Código de verificação</h3>
        <p class="mb-0 qj-p orientation">
          Digite o código de verificação que
          <strong>enviamos por SMS</strong> para prosseguir.
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          dense
          filled
          rounded
          hide-details
          placeholder="0000"
          autocomplete="off"
          inputmode="numeric"
          class="qj-input-verification"
          v-mask="'####'"
          v-model="code"
        ></v-text-field>
        <!-- <InputVerification
          :type="'OPPORTUNITY'"
          @sending="sending = $event"
          @validation="next($event)"
        /> -->
      </v-col>
      <v-col cols="12" v-show="alert">
        <v-alert
          :value="alert"
          color="red"
          text
          transition="scale-transition"
          class="qj-alert-error"
        >
          Código de verificação é inválido. Verifique e tente novamente.
        </v-alert>
      </v-col>
      <v-col cols="12" v-show="codeResent">
        <v-alert
          :value="codeResent"
          color="green"
          text
          transition="scale-transition"
          class="qj-alert-error"
        >
          Um novo código foi enviado.
        </v-alert>
      </v-col>
      <v-col v-show="sending" cols="12" class="pa-0 loading-container">
        <v-progress-linear
          indeterminate
          rounded
          color="green"
          height="8"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12" class="pt-2 flex-content text-center mt-1">
        <v-btn
          @click="resendCode()"
          class="qj-btn qj-bg-secondary-light"
          id="btn__receive__sms_error"
          :disabled="sendingPasscode"
          :loading="sendingPasscode"
        >
          Não recebi o código
        </v-btn>
      </v-col>
    </v-row>
    <!-- Footer -->
    <qj-footer view="cadastro/verificacao" />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import rules from "@/assets/js/rules";
import environment from "@/assets/js/environment";
import opportunityService from "@/services/opportunity";
import authenticationService from "@/services/authentication";
// import InputVerification from "@/components/InputVerification";

export default {
  // components: {
  //   InputVerification,
  // },
  data: () => ({
    rules: rules,
    // Indica se o código está sendo enviado
    sendingPasscode: false,
    code: null,
    alert: false,
    dialog: false,
    sending: false,
    codeResent: false,
    client: store.getters["opportunity/getClient"],
  }),
  created() {
    if (!this.client.id) {
      window.location = environment.landingUrl;
    }
  },
  watch: {
    code() {
      if (this.code.length == 4) this.next();
    },
  },
  methods: {
    ...mapActions("opportunity", ["ActionsSetTaskId"]),
    next() {
      this.codeResent = false;
      this.alert = false;
      this.sending = true;
      authenticationService
        .confirm(this.prepareForm())
        .then((res) => {
          if (res.data)
            if (res.data.verification == "fail") {
              this.alert = true;
            } else {
              this.ActionsSetTaskId(res.data.task_id);
              this.alert = false;
              this.$router.push({ name: "OpportunityDebtCreditor" });
            }
        })
        .catch(() => {
          this.alert = true;

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => (this.sending = false));
    },
    prepareForm() {
      return {
        code: this.code,
        document: this.client.document || "",
        _hdoc: store.getters["opportunity/getHdoc"] || "",
      };
    },
    resendCode() {
      this.alert = false;
      this.codeResent = false;
      this.sendingPasscode = true;

      console.info("Reenviando o código de confirmação.");
      
      opportunityService
        .resendCode({
          _hdoc: store.getters["opportunity/getHdoc"] || "",
        })
        .then(() => {
          console.info("Código de confirmação reenviado.");
          this.codeResent = true;
        })
        .catch((err) => {
          console.error("Não foi possível enviar o código de confirmação.", err);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.sendingPasscode = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-weight: 800;
  margin-top: 6px;
}

.orientation {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 20px 20px;
}

.image-container {
  padding-top: 40px;

  img {
    width: 90px;
    height: 90px;
  }
}

.loading-container {
  margin: -8px auto 16px;
  max-width: 275px;
}
</style>
